import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormCheckbox, Row } from "shards-react";
import AutocompleteArticulo from "./AutocompleteArticulo";
import FormularioDescripcion from "./FormularioDescripcion";
import FormularioCantidadPrecio from "./FormularioDescripcion/FormularioCantidadPrecio";
import { errorNotification } from "../../../components/Notifications";
import {
  agregarDescripcion,
  cambiarPrecioLista,
} from "../../../Redux/Actions/presupuestoActions";
import FiltroBuscador from "../../Proveedores/Catalogo/FiltroBuscador";
import { getFilters } from "../../Proveedores/Catalogo/utils";
import { Grid } from "@material-ui/core";
import FiltroStock from "../../Proveedores/Catalogo/FiltroStock";
import {
  localStorageDefaultConfig,
  setOptionDefaultConfigLocalStorage,
} from "../../../utils/localStorageUtils";
import useArticulosSucursal from "../../../customHooks/useGetArticulosSucursal";
import { useFiltroStock } from "../../../customHooks/useFiltroStock";

export default function FormularioArticulo({ id, isVenta, idOrden }) {
  const { checkCatalogoLS } = localStorageDefaultConfig();
  const [checkCatalogo, setCheckCatalogo] = useState(checkCatalogoLS);
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [listfilters, setListfilters] = useState([]);
  const [update, setUpdate] = useState(false);
  const {
    detalles,
    isPrecioLista,
    round_prices,
    descuento,
    medioPago,
    cliente,
  } = useSelector((state) => state.presupuesto);
  const dispatch = useDispatch();
  const { dataArticulos } = useArticulosSucursal({
    listfilters,
    useMeli: false,
    proveedorSelect: "0",
    fraccionar_precio: true,
  });
  const { loading, articulos, getArticulosSucursal, filters } = dataArticulos;
  const [alteredArticulos, setAlteredArticulos] = useState([]);

  const {
    checkedArt,
    soloStock,
    sinStock,
    tieneMeli,
    filter,
    busquedaArticulo,
    debouncedSearchTerm,
    setCheckedArt,
    setSoloStock,
    setSinStock,
    setTieneMeli,
    setFilter,
    setBusqueda,
  } = filters;

  const {
    handleChangeArt,
    handleChangeTodosMisArt,
    handleChangeSoloStock,
    handleChangeSinStock,
    handleChangeConVinculacionMeli,
  } = useFiltroStock(setCheckedArt, setSoloStock, setSinStock, setTieneMeli);

  useEffect(() => {
    getArticulosSucursal();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    update && getArticulosSucursal();
  }, [update]);

  useEffect(() => {
    const processedPiezaIds = new Set();

    const filtered = articulos
      .map((articulo) => ({
        ...articulo,
        descripcion: articulo.descripcion || "Sin descripción",
      }))
      .filter((articulo) => {
        if (!articulo.pieza_id) return true;

        if (processedPiezaIds.has(articulo.pieza_id)) {
          return false;
        }

        processedPiezaIds.add(articulo.pieza_id);
        return true;
      });

    setAlteredArticulos(filtered);
  }, [articulos]);

  /** Funcion que setea el value del autocomplete y cambia el check */
  const handleChangeCheckCatalogo = () => {
    setAutocompleteValue(null);
    setCheckCatalogo(!checkCatalogo);
    setOptionDefaultConfigLocalStorage("checkCatalogoLS", !checkCatalogo);
  };

  /** Funcion para el submit del formulario, seatea el formulario y el autocomplete y lo agrega a redux */
  const handleAgregarDescripcion = ({ values, formFunctions }) => {
    if (!autocompleteValue) {
      errorNotification("Elija una descripción");
      return;
    }
    const newPrecio =
      (values.cantidad * Number(values.precio)) / Math.trunc(values.cantidad);

    const newPrecioMenorQueCero = values.cantidad * Number(values.precio);

    let nuevaDescripcion = {
      ...values,
      cantidad: values.cantidad < 1 ? 1 : Math.trunc(values.cantidad),
      precio: values.cantidad < 1 ? newPrecioMenorQueCero : newPrecio,
      descripcion: autocompleteValue.descripcion,
    };
    formFunctions.resetForm();
    setAutocompleteValue(null);
    document.getElementById("agregar_descripcion").focus();
    dispatch(
      agregarDescripcion(
        detalles,
        nuevaDescripcion,
        round_prices,
        descuento,
        medioPago,
        configGeneral.redondeo_precio,
        isVenta && cliente
          ? cliente.clienteDescuento
          : { isClienteDescuento: false, recargoLarger: null, allRecargos: [] },
      ),
    );
  };
  const getListFilters = () => {
    getFilters().then((res) => {
      setListfilters(res);
      res.length > 0 &&
        setFilter(localStorage.getItem("filtroArticulos") || res[0].id);
    });
  };

  useEffect(() => {
    if (autocompleteValue) {
      document.getElementById("cantidadDescripcion").focus();
    }
  }, [autocompleteValue]);

  useEffect(() => {
    checkCatalogo
      ? document.getElementById("buscador_articulos").focus()
      : document.getElementById("agregar_descripcion").focus();
  }, [checkCatalogo]);

  const handleChangeMoreFilters = async (value) => {
    switch (value) {
      case 1:
        handleChangeArt(true);
        break;
      case 2:
        handleChangeTodosMisArt(true);
        break;
      case 3:
        handleChangeSoloStock(true);
        break;
      case 4:
        handleChangeSinStock(true);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    setUpdate(true);
    setTimeout(() => {
      setUpdate(false);
    }, 900);
  }, [filter, checkedArt, soloStock, sinStock]);

  useEffect(() => {
    getListFilters();
    let value = localStorage.getItem("filtroMostrar");
    if (value) handleChangeMoreFilters(parseInt(value));
  }, []);

  return (
    <Grid container spacing={1} style={{ marginTop: 2 }}>
      <Grid
        item
        // style={{
        //   display: checkCatalogo ? "flex" : "",
        //   justifyContent: "center",
        // }}
        xs={12}
        md={4}
        lg={4}
      >
        {!idOrden && (
          <FormCheckbox
            className="mt-1"
            toggle
            onChange={handleChangeCheckCatalogo}
            checked={checkCatalogo}
            name="check_catalogo"
          >
            <span style={styles.texto}>
              {checkCatalogo ? "Catálogo" : "Descripción"}
            </span>
          </FormCheckbox>
        )}
      </Grid>
      {checkCatalogo && (
        <Grid item xs={12} md={4} lg={4}>
          <div style={{ display: "flex" }}>
            <FiltroBuscador
              listfilters={listfilters}
              filter={filter}
              setFilter={setFilter}
              size="small"
              name="filtro_buscador"
            />
          </div>
        </Grid>
      )}
      {checkCatalogo && (
        <Grid item xs={12} md={4} lg={4}>
          <FiltroStock
            filter={
              !soloStock && !sinStock && !checkedArt
                ? 1
                : checkedArt && !soloStock && !sinStock
                  ? 2
                  : soloStock && checkedArt && !sinStock
                    ? 3
                    : sinStock && checkedArt && !soloStock
                      ? 4
                      : 1
            }
            setFilter={(value) => {
              localStorage.setItem("filtroMostrar", value);
              handleChangeMoreFilters(value);
            }}
          />
        </Grid>
      )}
      {configGeneral.precio_mayorista && (
        <Grid
          item
          xs={6}
          md={6}
          lg={checkCatalogo ? 4 : 6}
          className="d-flex justify-content-md-start justify-content-sm-end justify-content-end"
        >
          <FormCheckbox
            className="mt-1"
            toggle
            onChange={() =>
              dispatch(
                cambiarPrecioLista(
                  detalles,
                  !isPrecioLista,
                  round_prices,
                  descuento,
                  medioPago,
                  configGeneral.redondeo_precio,
                  isVenta && cliente
                    ? cliente.clienteDescuento
                    : {
                        isClienteDescuento: false,
                        recargoLarger: null,
                        allRecargos: [],
                      },
                ),
              )
            }
            checked={isPrecioLista}
            name="check_mayorista"
          >
            <span style={styles.texto}>Precio Mayorista</span>
          </FormCheckbox>
        </Grid>
      )}
      <Grid item xs={12} md={12} lg={12} className="pb-3">
        {checkCatalogo && (
          <AutocompleteArticulo
            busquedaArticulo={busquedaArticulo}
            setBusqueda={setBusqueda}
            articulos={alteredArticulos}
            loading={loading}
            clienteDescuento={
              isVenta && cliente
                ? cliente.clienteDescuento
                : {
                    isClienteDescuento: false,
                    recargoLarger: null,
                    allRecargos: [],
                  }
            }
            idOrden={idOrden}
          />
        )}
        {!checkCatalogo && (
          <FormularioDescripcion
            autocompleteValue={autocompleteValue}
            setAutocompleteValue={setAutocompleteValue}
            isVenta={isVenta}
          />
        )}
      </Grid>{" "}
      {!checkCatalogo && (
        <Grid item xs={12} lg={12}>
          <FormularioCantidadPrecio
            handleAgregarDescripcion={handleAgregarDescripcion}
            idOrden={idOrden}
            isVenta={isVenta}
          />
        </Grid>
      )}
    </Grid>
  );
}
const styles = {
  texto: {
    display: "flex",
    fontSize: 20,
    marginTop: -22,
    marginLeft: -4,
  },
};
