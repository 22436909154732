import React, { useState, useEffect } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { getPiezas } from "../../services/piezasService";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    header: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(3),
    },
    title: {
      margin: 0,
      flexGrow: 1,
    },
    tableContainer: {
      width: "100%",
      boxShadow: theme.shadows[1],
      borderRadius: theme.shape.borderRadius,
    },
    table: {
      minWidth: 650,
    },
    loadingContainer: {
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(4),
    },
  }),
);

const Piezas = () => {
  const classes = useStyles();
  const [piezas, setPiezas] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    fetchPiezas();
  }, []);

  const fetchPiezas = async () => {
    try {
      const response = await getPiezas();
      setPiezas(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching piezas:", error);
      setLoading(false);
    }
  };

  const handleAddNew = () => {
    history.push("/piezas/new");
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <div className={classes.header}>
          <h1 className={classes.title}>Piezas</h1>
          <Button variant="contained" color="primary" onClick={handleAddNew}>
            Agregar Nueva Pieza
          </Button>
        </div>
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          ) : (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Descripción</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {piezas &&
                  piezas.map((pieza) => (
                    <TableRow key={pieza.id} hover>
                      <TableCell>{pieza.id}</TableCell>
                      <TableCell>{pieza.nombre}</TableCell>
                      <TableCell>{pieza.descripcion}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Piezas;
