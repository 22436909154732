import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { DefaultLayout } from "./layouts";
import AdministrarModulos from "./views/AdministrarModulos/AdministrarModulos";
import index from "./views/Articulos/FormularioArticulo";
import CrearAuditoriaPedido from "./views/AuditoriaPedido/CrearAuditoriaPedido/CrearAuditoriaPedido";
import EditarAuditoriaPedido from "./views/AuditoriaPedido/EditarAuditoriaPedido/EditarAuditoriaPedido";
import ChatGPT from "./views/ChatGPT";
import Clientes from "./views/Clientes";
import IndexBilleteraVirtual from "./views/Clientes/BilleteraVirtual/Index";
import FormularioCliente from "./views/Clientes/FormularioCliente";
import JoinComponentsTabs from "./views/Compras/JoinComponentsTabs";
import ConfigurationPadreTabs from "./views/Configuration/ConfigurationPadreTabs";
import Deposito from "./views/Configuration/Deposito/Deposito";
import Ubicacion from "./views/Configuration/Deposito/Ubicacion";
import Zona from "./views/Configuration/Deposito/Zona";
import FormFilters from "./views/Configuration/Filtros/Components/FormFilters";
import IndexFilters from "./views/Configuration/Filtros/IndexFilters";
import General from "./views/Configuration/General";
import Panel from "./views/Configuration/Panel/Panel";
import Dashboard from "./views/Dashboard";
import DashboardInformes from "./views/DashboardInformes";
import FacturasAdeudadas from "./views/FacturasAdeudadas/index";
import FormularioAfip from "./views/FormularioAfip";
import AuditoriasPedidos from "./views/Informe/AuditoriasPedidos/AuditoriasPedidos";
import Cheques from "./views/Informe/Cheques";
import InformeCobros from "./views/Informe/Cobros";
import Combinacion from "./views/Informe/Combinacion";
import InformeCompras from "./views/Informe/Compras";
import Parciales from "./views/Informe/InformesParciales";
import LibroIva from "./views/Informe/LibroIVA";
import MovimientoCaja from "./views/Informe/MovimientoCaja";
import MovimientoStock from "./views/Informe/MovimientoStock";
import PagoCompras from "./views/Informe/PagoCompras/PagoCompras";
import Pagos from "./views/Informe/Pagos";
import PedidosInforme from "./views/Informe/Pedidos";
import InformeRendimientos from "./views/Informe/Rendimiento/InformeRendimientos";
import Retenciones from "./views/Informe/Retenciones/Retenciones";
import IndexInformeVentaAnualMensual from "./views/Informe/VentasAnualMensual";
import IndexInformeVentasPorMarca from "./views/Informe/VentasPorMarca";
import Marcas from "./views/Marcas";
import FormularioParametros from "./views/ParametrosLista";
import Presupuesto from "./views/Presupuesto";
import IndexAjuste from "./views/Proveedores/AjustePrecioCosto/IndexAjuste";
import ArticulosRepetidos from "./views/Proveedores/ArticulosRepetidos";
import CatalogoArticulos from "./views/Proveedores/Catalogo/CatalogoArticulos";
import ImprimirEtiquetas from "./views/Proveedores/Catalogo/ImprimirEtiquetas";
import ComprasAPagar from "./views/Proveedores/ComprasAPagar/ComprasAPagar";
import CuentaCorrienteProveedor from "./views/Proveedores/CuentaCorrienteProveedor/CuentaCorrienteProveedor";
import FormularioProveedor from "./views/Proveedores/NuevoFormProveedor";
import ParametrosListasTabs from "./views/Proveedores/ParametrosListasTabs";
import Pedidos from "./views/Proveedores/Pedidos";
import ProveedorCuentaTabs from "./views/Proveedores/ProveedorCuentaTabs";
import ProveedoresTabs from "./views/Proveedores/ProveedoresTabs";
import ListaCargaListas from "./views/Proveedores/listasManuales/ListaCargaListas";
import Remitos from "./views/Remitos";
import RepuestoProveedor from "./views/RepuestoProveedor/RepuestoProveedor";
import TarjetasGestion from "./views/Tarjetas/index";
import AddMovCaja from "./views/Till/AddMovCaja";
import CajaVenta from "./views/Till/CajaVenta";
import Venta from "./views/Venta";
import SincronizacionVentasAfip from "./views/administrador/afip/SincronizacionVentasAfip";
import ListaCuentas from "./views/cuentas/ListaCuenta";
import VistaCuenta from "./views/cuentas/VistaCuenta";
import FormularioIntegracion from "./views/integraciones/Integracion/FormularioIntegracion";
import Integracion from "./views/integraciones/Integracion/Integraciones";
import { ComprasAfip } from "./views/integraciones/comprasafip";
import ListaProductosVinculados from "./views/integraciones/mercadolibre/ListaProductosVinculados";
import TabsMeli from "./views/integraciones/mercadolibre/TabsMeli";
import VinculacionMasivaPublicaciones from "./views/integraciones/mercadolibre/VinculacionMasivaPublicaciones/VinculacionMasivaPublicaciones";
import VinculacionPublicacionesMELI from "./views/integraciones/mercadolibre/VinculacionPublicaciones";
import PublicacionesWoocommerce from "./views/integraciones/woocommerce/PublicacionesWoocommerce";
import VinculacionPublicacionesWoocommerce from "./views/integraciones/woocommerce/VinculacionPublicaciones";
import Piezas from "./views/Piezas";
import AgregarPieza from "./views/Piezas/AgregarPieza";

export const moduloAfipRoute = () => ({
  path: "/afip-admin/",
  exact: true,
  layout: DefaultLayout,
  component: FormularioAfip,
});

export const moduloEstadisticas = () => ({
  path: "/estadisticas",
  exact: true,
  layout: DefaultLayout,
  component: Dashboard,
});

export const moduloDashboardPath = () => ({
  path: "/",
  exact: true,
  layout: DefaultLayout,
  component: () => <Redirect to="/informes" />,
});

export const moduloVentaRoute = () => ({
  path: "/",
  exact: true,
  layout: DefaultLayout,
  component: () => <Redirect to="/informes" />,
});

export const moduloReVentaRoute = () => ({
  path: "/ventas/re-venta/:idVenta/",
  exact: true,
  layout: DefaultLayout,
  component: Venta,
});

export const moduloVentaOrdenRoute = () => ({
  path: "/ventas/orden/:idOrden/",
  exact: true,
  layout: DefaultLayout,
  component: Venta,
});

export const moduloVentaPresupuestoRoute = () => ({
  path: "/ventas/presupuesto/:idPresupuesto/",
  exact: true,
  layout: DefaultLayout,
  component: Venta,
});

export const moduloVentaRemitoRoute = () => ({
  path: "/ventas/remito/:idRemito/",
  exact: true,
  layout: DefaultLayout,
  component: Venta,
});

export const moduloPresupuestoRoute = () => ({
  path: "/presupuesto",
  exact: true,
  layout: DefaultLayout,
  component: Presupuesto,
});

export const moduloPresupuestoIdRoute = () => ({
  path: "/presupuesto/:idPresupuesto/",
  exact: true,
  layout: DefaultLayout,
  component: Presupuesto,
});

export const moduloRemitoRoute = () => ({
  path: "/remito",
  exact: true,
  layout: DefaultLayout,
  component: Remitos,
});

export const moduloMovCajaRoute = () => ({
  path: "/caja/mov-caja",
  layout: DefaultLayout,
  component: AddMovCaja,
});

export const moduloCajaVentaRoute = () => ({
  path: "/caja/ventas",
  layout: DefaultLayout,
  component: CajaVenta,
});

export const moduloVentasRoute = () => ({
  path: "/ventas/",
  layout: DefaultLayout,
  exact: true,
  component: Venta,
});

export const moduloTarjetasRoute = () => ({
  path: "/tarjetas",
  exact: true,
  layout: DefaultLayout,
  component: TarjetasGestion,
});

export const moduloConfGeneralRoute = () => ({
  path: "/conf-general",
  exact: true,
  layout: DefaultLayout,
  component: General,
});

export const moduloConfFiltrosRoute = () => ({
  path: "/conf-filtros",
  exact: true,
  layout: DefaultLayout,
  component: IndexFilters,
});

export const moduloConfFiltrosIdRoute = () => ({
  path: "/conf-filtros/:idFiltro/",
  layout: DefaultLayout,
  component: FormFilters,
});

export const moduloConfFiltrosNuevoRoute = () => ({
  path: "/conf-filtros/nuevo",
  layout: DefaultLayout,
  component: FormFilters,
});

export const moduloPanelRoute = () => ({
  path: "/panel",
  layout: DefaultLayout,
  component: Panel,
});

export const moduloConfiguracionRoute = () => ({
  path: "/config/:tab",
  layout: DefaultLayout,
  component: ConfigurationPadreTabs,
});

export const moduloClientesRoute = () => ({
  path: "/clientes/ver-clientes",
  layout: DefaultLayout,
  component: Clientes,
});

export const moduloClienteIdRoute = () => ({
  path: "/clientes/modificar/:idCliente/",
  layout: DefaultLayout,
  component: FormularioCliente,
});

export const moduloCargarClienteRoute = () => ({
  path: "/clientes/cargar-cliente",
  layout: DefaultLayout,
  component: FormularioCliente,
});

export const moduloArticulosNuevoRoute = () => ({
  path: "/articulos/nuevo/",
  layout: DefaultLayout,
  component: index,
});

export const moduloModificarArticuloRoute = () => ({
  path: "/articulos/modificar-articulo/:idArticulo/",
  layout: DefaultLayout,
  component: index,
});

export const moduloCatalogosRoute = () => ({
  path: "/catalogos",
  layout: DefaultLayout,
  component: CatalogoArticulos,
});

export const moduloImprimirEtiquetasRoute = () => ({
  path: "/catalogo/imprimir-etiquetas",
  layout: DefaultLayout,
  component: ImprimirEtiquetas,
});

export const moduloVerProveedoresRoute = () => ({
  path: "/proveedores/:tab",
  layout: DefaultLayout,
  component: ProveedoresTabs,
});

export const moduloCargarProveedorRoute = () => ({
  path: "/proveedor/cargar-proveedor",
  layout: DefaultLayout,
  component: FormularioProveedor,
});

export const moduloModificarProveedorRoute = () => ({
  path: "/proveedor/modificar/:idProveedor/",
  layout: DefaultLayout,
  component: FormularioProveedor,
});

export const moduloCatalogoProveedorRoute = () => ({
  path: "/catalogo-proveedor",
  layout: DefaultLayout,
  component: CatalogoArticulos,
});

export const moduloParametrosProveedorRoute = () => ({
  path: "/proveedor/parametros/:idProveedor",
  layout: DefaultLayout,
  component: FormularioParametros,
});

export const moduloComprasAdeudadas = () => ({
  path: "/proveedor/compras-adeudadas/:idProveedor",
  layout: DefaultLayout,
  component: ComprasAPagar,
});

export const moduloCuentaCorrienteProveedor = () => ({
  path: "/proveedor/cuenta-corriente-proveedor/:idProveedor",
  layout: DefaultLayout,
  component: CuentaCorrienteProveedor,
});

export const moduloAjustePrecioCostoRoute = () => ({
  path: "/ajuste-precio-costo",
  layout: DefaultLayout,
  component: IndexAjuste,
});

export const moduloFacturasAdeudadasRoute = () => ({
  path: "/facturas-adeudadas/:idCliente/",
  layout: DefaultLayout,
  component: FacturasAdeudadas,
});

export const moduloPagosRoute = () => ({
  path: "/cliente/pagos/:idCliente/",
  layout: DefaultLayout,
  component: Pagos,
});

export const moduloClienteChequesRoute = () => ({
  path: "/cliente/cheques/:idCliente/",
  layout: DefaultLayout,
  component: Cheques,
});

export const moduloClienteBilleteraVirtual = () => ({
  path: "/cliente/billetera-virtual/:idCliente/",
  layout: DefaultLayout,
  component: IndexBilleteraVirtual,
});

export const moduloInformeMovimientosBilletera = () => ({
  path: "/informe/movimientos-billetera/",
  layout: DefaultLayout,
  component: InformeCobros,
});

export const moduloInformeMovimientoStockRoute = () => ({
  path: "/informe/movimientos-stock/",
  layout: DefaultLayout,
  component: MovimientoStock,
});

export const moduloMovimientoStockArticuloRoute = () => ({
  path: "/informe/articulo/mov-stock/:idProveedor/:idRepuestoSucursal",
  layout: DefaultLayout,
  component: MovimientoStock,
});

export const moduloInformeMovimientoCajaRoute = () => ({
  path: "/informe/movimientos-caja/",
  layout: DefaultLayout,
  component: MovimientoCaja,
});

export const moduloInformeChequesRoute = () => ({
  path: "/informe/cheques/:idCliente/",
  layout: DefaultLayout,
  component: Cheques,
});

export const moduloInformePromedioDiario = () => ({
  path: "/informe/promediodiario",
  layout: DefaultLayout,
  component: IndexInformeVentaAnualMensual,
});

export const moduloInformesRoute = () => ({
  path: "/informes/",
  layout: DefaultLayout,
  component: DashboardInformes,
});

export const moduloPagoCompras = () => ({
  path: "/informe/pago-compras/",
  layout: DefaultLayout,
  component: PagoCompras,
});

export const moduloPagoComprasProveedor = () => ({
  path: "/proveedor/pago-compras/:idProveedor",
  layout: DefaultLayout,
  component: PagoCompras,
});

export const moduloUbicacionRoute = () => ({
  path: "/ubicaciones",
  layout: DefaultLayout,
  component: Ubicacion,
});

export const moduloDepositoRoute = () => ({
  path: "/deposito",
  layout: DefaultLayout,
  component: Deposito,
});

export const moduloZonaRoute = () => ({
  path: "/zona",
  layout: DefaultLayout,
  component: Zona,
});

export const moduloInformeLibroIvaRoute = () => ({
  path: "/informe/libro-contables/",
  layout: DefaultLayout,
  component: LibroIva,
});

export const moduloInformeVentasPorMarcaRoute = () => ({
  path: "/informe/ventas-por-marca/",
  layout: DefaultLayout,
  component: IndexInformeVentasPorMarca,
});

export const moduloInformePedidosRoute = () => ({
  path: "/informe/pedidos",
  layout: DefaultLayout,
  component: PedidosInforme,
});

export const moduloInformeParcialesRoute = () => ({
  path: "/informe/informe-parcial/",
  layout: DefaultLayout,
  component: Parciales,
});

export const moduloMarcasProveedorRoute = () => ({
  path: "/marcas-proveedor/:idProveedor/",
  layout: DefaultLayout,
  component: Marcas,
});

export const moduloComprasRoute = () => ({
  path: "/compras/:tab",
  exact: true,
  layout: DefaultLayout,
  component: JoinComponentsTabs,
});

// export const moduloComprasNormalRoute = () => ({
//   path: "/compras/normal/",
//   exact: true,
//   layout: DefaultLayout,
//   component: Compras,
// });

// export const moduloComprasRapidaRoute = () => ({
//   path: "/compras/rapida/",
//   exact: true,
//   layout: DefaultLayout,
//   component: ComprasRapidas,
// });

export const moduloInformeComprasRoute = () => ({
  path: "/mis-compras/",
  layout: DefaultLayout,
  component: InformeCompras,
});

export const moduloPedidosRoute = () => ({
  path: "/proveedores/pedidos",
  layout: DefaultLayout,
  component: Pedidos,
});

export const moduloPedidosEditar = () => ({
  path: "/pedidos/editar/:idPedido/",
  layout: DefaultLayout,
  component: Pedidos,
});

export const moduloIntegracionesRoute = () => ({
  path: "/mis-integraciones",
  layout: DefaultLayout,
  component: Integracion,
});

export const moduloNuevaIntegracionRoute = () => ({
  path: "/nueva-integracion/:tipoIntegracion/:idIntegracion",
  layout: DefaultLayout,
  component: FormularioIntegracion,
});

export const moduloPublicacionesWoocommerceRoute = () => ({
  path: "/mis-publicaciones/woocommerce",
  layout: DefaultLayout,
  component: PublicacionesWoocommerce,
});

export const moduloMercadoLibreRoute = () => ({
  path: "/integraciones/mercadolibre",
  layout: DefaultLayout,
  component: ListaProductosVinculados,
});

export const moduloPublicacionesMercadoLibreRoute = () => ({
  path: "/:tab/mercadolibre",
  exact: true,
  layout: DefaultLayout,
  component: TabsMeli,
});

export const moduloVinculacionPublicacionesMELIRoute = () => ({
  path: "/vinculacion-publicacion/mercadolibre/:idPublicacion",
  layout: DefaultLayout,
  component: VinculacionPublicacionesMELI,
});

export const moduloVinculacionPublicacionesWoocommerceRoute = () => ({
  path: "/vinculacion-publicacion/woocommerce/:idProducto",
  layout: DefaultLayout,
  component: VinculacionPublicacionesWoocommerce,
});

export const moduloComprasAfip = () => ({
  path: "/compras-afip",
  layout: DefaultLayout,
  component: ComprasAfip,
});

export const moduloListasManuales = () => [
  {
    path: "/proveedor/listas-manuales/:idProveedor/",
    layout: DefaultLayout,
    component: ListaCargaListas,
  },
  {
    path: "/proveedores/listas-manuales/",
    layout: DefaultLayout,
    component: ListaCargaListas,
  },
];

export const moduloAdministrador = [
  {
    path: "/administrador/afip/sincronizar-ventas/",
    layout: DefaultLayout,
    component: SincronizacionVentasAfip,
  },
];

export const moduloRetenciones = () => ({
  path: "/informe/retenciones/",
  layout: DefaultLayout,
  component: Retenciones,
});
export const moduloInformeCombinacionRoute = () => ({
  path: "/informe/:tab",
  exact: true,
  layout: DefaultLayout,
  component: Combinacion,
});

export const moduloArticulosRepetidos = () => ({
  path: "/proveedores/articulos-duplicados/",
  layout: DefaultLayout,
  component: ArticulosRepetidos,
});

export const moduloChatGPT = () => ({
  path: "/chatbot",
  layout: DefaultLayout,
  component: ChatGPT,
});

export const moduloAuditoriasDePedidos = () => ({
  path: "/informe/auditorias/pedidos",
  layout: DefaultLayout,
  component: AuditoriasPedidos,
});

export const moduloCrearAuditoriaPedido = () => ({
  path: "/auditoria/crear/:idPedido",
  layout: DefaultLayout,
  component: CrearAuditoriaPedido,
});

export const moduloEditarAuditoriaPedido = () => ({
  path: "/auditoria/editar/:idAuditoria",
  layout: DefaultLayout,
  component: EditarAuditoriaPedido,
});

export const moduloCuentaProveedores = () => ({
  path: "/proveedor/cuenta/:tab/:idProveedor",
  layout: DefaultLayout,
  component: ProveedorCuentaTabs,
});

export const moduloParametrosListas = () => ({
  path: "/proveedor/parametros-listas/:tab/:idProveedor",
  layout: DefaultLayout,
  component: ParametrosListasTabs,
});

export const moduloAdministrarModulos = () => ({
  path: "/administrar-modulos",
  layout: DefaultLayout,
  component: AdministrarModulos,
});

export const moduloVinculacionMasivaPublicacionesMeli = [
  {
    path: "/publicacion/vinculacion-masiva/mercadolibre",
    layout: DefaultLayout,
    component: VinculacionMasivaPublicaciones,
  },
];

export const moduloCuentas = [
  {
    path: "/cuentas",
    layout: DefaultLayout,
    component: ListaCuentas,
  },
  {
    path: "/cuenta/:cuentaId/movimientos",
    layout: DefaultLayout,
    component: VistaCuenta,
  },
];

export const moduloInformeRendimientos = () => ({
  path: "/informe/rendimientos/articulos/",
  layout: DefaultLayout,
  component: InformeRendimientos,
});

export const moduloRepuestoProveedor = [
  {
    path: "/repuesto-proveedor/:idRepuestoProveedor",
    layout: DefaultLayout,
    component: RepuestoProveedor,
  },
];

export const moduloPiezas = () => ({
  path: "/piezas",
  layout: DefaultLayout,
  component: Piezas,
});

export const moduloAgregarPieza = () => ({
  path: "/piezas/new",
  layout: DefaultLayout,
  component: AgregarPieza,
});
