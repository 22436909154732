export default function columnasPorSucursales(sucursales, esVisible, config) {
  let lodash = require("lodash");
  let array = [];
  if (sucursales.length === 0) {
    array = [
      "Pieza",
      esVisible(config, "Imagen") ? "Imagen" : "",
      esVisible(config, "Artículo") ? "Artículo" : "",
      esVisible(config, "Original") ? "Original" : "",
      esVisible(config, "Auxiliar") ? "Auxiliar" : "",
      esVisible(config, "Descripción") ? "Descripcion" : "",
      esVisible(config, "Marca") ? "Marca" : "",
      esVisible(config, "Rubro") ? "Rubro" : "",
      esVisible(config, "Subrubro") ? "Subrubro" : "",
      esVisible(config, "Observaciones") ? "Observaciones" : "",
      esVisible(config, "L. de Precios") ? "L. de Precios" : "",
      esVisible(config, "P. Costo") ? "P. Costo" : "",
      esVisible(config, "P. Lista") ? "P. Lista" : "",
      esVisible(config, "P. Venta") ? "P. Venta" : "",
      esVisible(config, "Stock") ? "Stock" : "",
      esVisible(config, "Stock deseado") ? "Stock deseado" : "",
      esVisible(config, "Proveedor") ? "Proveedor" : "",
      esVisible(config, "Ubicación") ? "Ubicación" : "",
      esVisible(config, "Fecha ultima actualizacion")
        ? "Última actualización"
        : "",
      "Opciones",
    ];
    return lodash.compact(array);
  } else {
    array = [
      "Pieza",
      esVisible(config, "Imagen") ? "Imagen" : "",
      esVisible(config, "Artículo") ? "Artículo" : "",
      esVisible(config, "Original") ? "Original" : "",
      esVisible(config, "Auxiliar") ? "Auxiliar" : "",
      esVisible(config, "Descripción") ? "Descripcion" : "",
      esVisible(config, "Marca") ? "Marca" : "",
      esVisible(config, "Rubro") ? "Rubro" : "",
      esVisible(config, "Subrubro") ? "Subrubro" : "",
      esVisible(config, "Observaciones") ? "Observaciones" : "",
      esVisible(config, "L. de Precios") ? "L. de Precios" : "",
      esVisible(config, "P. Costo") ? "P. Costo" : "",
      esVisible(config, "P. Lista") ? "P. Lista" : "",
      esVisible(config, "P. Venta") ? "P. Venta" : "",
      esVisible(config, "Stock") ? "Stock" : "",
      esVisible(config, "Stock deseado") ? "Stock deseado" : "",
      ...(esVisible(config, "Stock") ? sucursales.map((s) => s.nombre) : []),
      esVisible(config, "Proveedor") ? "Proveedor" : "",
      esVisible(config, "Ubicación") ? "Ubicación" : "",
      esVisible(config, "Fecha ultima actualizacion")
        ? "Última actualización"
        : "",

      "Opciones",
    ];
    return lodash.compact(array);
  }
}
