import { Box } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import RoomIcon from "@material-ui/icons/Room";
import { pdf } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EtiquetasA4 from "../../../components/ait-reusable/Etiquetas/EtiquetasA4";
import EtiquetasCarta from "../../../components/ait-reusable/Etiquetas/EtiquetasCarta";
import EtiquetasTicket from "../../../components/ait-reusable/Etiquetas/EtiquetasTicket";
import ModalTamanos from "../../../components/ait-reusable/Etiquetas/ModalTamaños";
import MenuB from "../../../components/ait-reusable/Menu/MenuB";
import ModalReutilizable from "../../../components/ait-reusable/Modal";
import TooltipWithoutIcon from "../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import { MODULOS } from "../../../constantes/modulos";
import { useModulosContext } from "../../../context/ModulosContext";
import { useGetSucursal } from "../../../customHooks/useGetSucursal";
import meliLogo from "../../../images/meli.png";
import { setArticuloVinculacion } from "../../../Redux/Actions/actionsVinculacionesMELI";
import { setArticuloVinculacionWoo } from "../../../Redux/Actions/actionsVinculacionesWoo";
import { getConfiguracionEtiquetasService } from "../../Configuration/ConfiguracionEtiquetas/Services";
import wooLogo from "../../integraciones/woocommerce/images/wooLogo.png";
import "../assets/styles.css";

const OpcionesCatalogo = ({
  articulo,
  listaPrecio,
  permisoParaEditarYVerTooltip,
  handleClickMenu,
  setDetallesProducto,
  handleOpenModalPedido,
  history,
  handleOpenVincularStock,
  piezaMode,
  onAddToPieza,
}) => {
  const dispatch = useDispatch();

  const { activo: moduloMeliActivo } = useModulosContext().tieneModuloActivo(
    MODULOS.MELI,
  );
  const [isLoading, setIsLoading] = useState(false);
  const { sucursal } = useGetSucursal();
  const [configuracionEtiquetas, setConfiguracionEtiqutas] = useState(null);
  const [openModalEtiqueta, setModalEtiqueta] = useState(false);
  const [formatoHoja, setFormatoHoja] = useState("Ticket");
  const [medidaSelected, setMedidaSelected] = useState(null);
  const [filasArray, setFilasArray] = useState({ cant: null, array: [] });
  const [columnasArray, setColumnasArray] = useState({ cant: null, array: [] });
  const [articuloCompleto, setArticuloCompleto] = useState(articulo);

  const { perfil } = useSelector((state) => state.loginReducer);
  const { meli_integracion, woocommerce_integracion } = useSelector(
    (state) => state.configGeneral.configGeneral,
  );

  const configGeneral = useSelector(
    (state) => state.configGeneral.configGeneral,
  );
  const isVendedor =
    perfil === "Vendedor" || perfil === "Empleado" ? true : false;

  const handleGetConfiguracionEtiquetas = async () => {
    try {
      const response = await getConfiguracionEtiquetasService();

      setConfiguracionEtiqutas(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetConfiguracionEtiquetas();
  }, []);

  useEffect(() => {
    let nuevoArt = { ...articulo };
    setArticuloCompleto(nuevoArt);
  }, [articulo]);

  useEffect(() => {
    let nuevoArt = { ...articuloCompleto };
    nuevoArt.idListaPrecio = listaPrecio;
    setArticuloCompleto(nuevoArt);
  }, [listaPrecio]);

  const hanldeBlobPDFCarta = () => {
    const blob = pdf(
      <EtiquetasCarta
        articulo={articulo}
        sucursal={sucursal}
        filasArray={filasArray}
        columnasArray={columnasArray}
        configGeneral={configGeneral}
        configuracionEtiqueta={configuracionEtiquetas}
      />,
    ).toBlob();
    blob.then((res) => {
      setIsLoading(true);
      const bloblURL = window.URL.createObjectURL(res);
      window.open(bloblURL);
      setIsLoading(false);
    });
  };

  const hanldeBlobPDFA4 = () => {
    const blob = pdf(
      <EtiquetasA4
        articulo={articulo}
        sucursal={sucursal}
        filasArray={filasArray}
        columnasArray={columnasArray}
        configGeneral={configGeneral}
        configuracionEtiqueta={configuracionEtiquetas}
      />,
    ).toBlob();
    blob.then((res) => {
      setIsLoading(true);
      const bloblURL = window.URL.createObjectURL(res);
      window.open(bloblURL);
      setIsLoading(false);
    });
  };

  const hanldeBlobPDFTicket = () => {
    const blob = pdf(
      <EtiquetasTicket
        alto={medidaSelected.alto}
        sucursal={sucursal}
        articulo={articulo}
        ancho={medidaSelected.ancho}
        filasArray={filasArray}
        columnasArray={columnasArray}
        configGeneral={configGeneral}
        configuracionEtiqueta={configuracionEtiquetas}
      />,
    ).toBlob();
    blob.then((res) => {
      setIsLoading(true);
      const bloblURL = window.URL.createObjectURL(res);
      window.open(bloblURL);
      setIsLoading(false);
    });
  };

  return (
    <Box className="d-flex justify-content-end">
      {piezaMode ? (
        <i
          onClick={() => onAddToPieza(articulo)}
          className="material-icons"
          title="Agregar a pieza"
          style={{ cursor: "pointer", marginRight: "8px" }}
        >
          add_circle
        </i>
      ) : (
        <>
          {!articulo.repuesto_sucursal && (
            <i
              onClick={() => handleClickMenu(articulo, "Sucursal")}
              className="material-icons iconAddToSucursal"
              title="Agregar a sucursal"
            >
              move_to_inbox
            </i>
          )}

          {!(
            articulo.repuesto_sucursal === null ||
            articulo.repuesto_sucursal.deposito === undefined
          ) && (
            <TooltipWithoutIcon
              placement={"bottom"}
              body={
                <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                  {`${
                    articulo.repuesto_sucursal.deposito.descripcion +
                    " - " +
                    articulo.repuesto_sucursal.zona.descripcion +
                    " - " +
                    articulo.repuesto_sucursal.estante.descripcion +
                    " - " +
                    articulo.repuesto_sucursal.fila.descripcion
                  }`}
                </h6>
              }
            >
              <RoomIcon />
            </TooltipWithoutIcon>
          )}

          {articulo.repuesto_sucursal && (
            <i
              onClick={() => {
                handleClickMenu(articulo, "Stock");
              }}
              className="material-icons iconStockEdit"
              title="Ajustar stock"
            >
              view_quilt
            </i>
          )}
          <i
            onClick={() => {
              handleClickMenu(articuloCompleto, "Carrito");
            }}
            className="material-icons iconCarritoEdit"
            title="Agregar a carrito"
          >
            shopping_cart
          </i>
          <MenuB>
            <MenuItem
              onClick={() => {
                setDetallesProducto(true);
              }}
            >
              <i className="material-icons iconStockEdit" title="Dar de baja">
                article
              </i>
              Detalles
            </MenuItem>

            <MenuItem
              onClick={() => {
                setModalEtiqueta(true);
              }}
            >
              <i className="material-icons iconEtiquetas">picture_as_pdf</i>
              Imprimir Etiqueta
            </MenuItem>

            {articulo.idRepuestoProveedor && (
              <MenuItem onClick={() => handleOpenModalPedido(articulo)}>
                <i
                  title="Agregar a pedido"
                  className="material-icons"
                  style={{ margin: "0 5px", cursor: "pointer", fontSize: 20 }}
                >
                  local_shipping
                </i>
                Agregar a pedido
              </MenuItem>
            )}

            {articulo &&
              permisoParaEditarYVerTooltip &&
              (articulo.repuesto_sucursal ? (
                <MenuItem
                  onClick={() => {
                    history.push(
                      `/articulos/modificar-articulo/${articulo.repuesto_sucursal.idRepuestoSucursal}`,
                    );
                  }}
                >
                  <i
                    className="material-icons iconEdit"
                    title="Editar producto"
                  >
                    edit
                  </i>
                  Editar producto
                </MenuItem>
              ) : (
                <TooltipWithoutIcon
                  placement={"top"}
                  body={
                    <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                      Este artículo no se puede editar porque no esta agregado a
                      la sucursal o no se tienen permisos.
                    </h6>
                  }
                >
                  <span>
                    <MenuItem
                      onClick={() => {
                        history.push(
                          `/articulos/modificar-articulo/${articulo.repuesto_sucursal.idRepuestoSucursal}`,
                        );
                      }}
                      disabled
                    >
                      <i
                        className="material-icons iconEdit"
                        title="Editar producto"
                      >
                        edit
                      </i>
                      Editar producto
                    </MenuItem>
                  </span>
                </TooltipWithoutIcon>
              ))}

            {articulo && !isVendedor && (
              <MenuItem
                onClick={() => {
                  handleClickMenu(articulo, "Baja");
                }}
              >
                <i className="material-icons iconStockEdit" title="Dar de baja">
                  delete
                </i>
                Eliminar
              </MenuItem>
            )}

            {articulo && articulo.repuesto_sucursal && (
              <MenuItem
                onClick={() => {
                  handleOpenVincularStock(articulo);
                }}
              >
                <i
                  className="material-icons iconStockEdit"
                  title={
                    articulo.repuesto_sucursal.codigo_vinculacion_sucursal
                      ? "Editar stock"
                      : "VincularStock"
                  }
                >
                  share
                </i>
                {articulo.repuesto_sucursal.codigo_vinculacion_sucursal
                  ? "Editar stock"
                  : "Vincular stock"}
              </MenuItem>
            )}

            {meli_integracion && moduloMeliActivo && (
              <MenuItem
                onClick={() =>
                  dispatch(setArticuloVinculacion(articulo, false))
                }
              >
                <img width={25} src={meliLogo} alt="logoML" />
                Vincular con MELI
              </MenuItem>
            )}

            {woocommerce_integracion && (
              <MenuItem
                onClick={() =>
                  dispatch(setArticuloVinculacionWoo(articulo, false))
                }
              >
                <img width={20} src={wooLogo} alt="logoWoo" />
                Vincular con Woocommerce
              </MenuItem>
            )}
          </MenuB>
        </>
      )}

      {openModalEtiqueta && (
        <ModalReutilizable
          open={openModalEtiqueta}
          handleClose={() => {
            setModalEtiqueta(false);
            setMedidaSelected(null);
            setFormatoHoja("Ticket");
          }}
          title={<label>Imprimir etiquetas</label>}
          content={
            <ModalTamanos
              selected={medidaSelected}
              setSelected={setMedidaSelected}
              filasArray={filasArray}
              setFilasArray={setFilasArray}
              columnasArray={columnasArray}
              setColumnasArray={setColumnasArray}
              setFormatoHoja={setFormatoHoja}
              formatoHoja={formatoHoja}
            />
          }
          messageAceptar={"Aceptar"}
          messageCancelar={"Cancelar"}
          handleSubmit={() => {
            if (formatoHoja === "A4") {
              hanldeBlobPDFA4();
            } else if (formatoHoja === "Carta") {
              hanldeBlobPDFCarta();
            } else {
              hanldeBlobPDFTicket();
            }
          }}
          disabled={medidaSelected ? false : true}
          size={medidaSelected ? "sm" : "xs"}
          openBackdrop={isLoading}
        />
      )}
      {/* <Backdrop open={isLoading} /> */}
    </Box>
  );
};

export default OpcionesCatalogo;
